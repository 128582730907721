<template>
  <div class="login">
    <TheLoginComponent />
  </div>
</template>

<script>
import TheLoginComponent from "../components/LoginComponent.vue";
export default {
  components: {
    TheLoginComponent,
  },
};
</script>
