<template>
  <div class="app">
    <div class="navigation">
      <input type="checkbox" id="hamburger-input" />
      <label for="hamburger-input" class="hamburger-label">
        <div class="hamburger"></div>
        <button @click="pageBack" class="back">zurück</button>
        <TheNavigationBar class="navigation-bar" />
        <div class="overlay"></div>
      </label>
    </div>
    <div class="content">
      <router-view />
    </div>
    <message-box ref="messageBox" />
  </div>
</template>

<script>
import TheNavigationBar from "./components/NavigationBar.vue";
import api from "./api";
import MessageBox from "./components/common/MessageBox.vue";
import { setMessageBoxInstance } from "./mixins/message-box";

export default {
  data() {
    return {};
  },
  methods: {
    pageBack() {
      this.$router.go(-1);
    },
  },
  components: {
    TheNavigationBar,
    MessageBox,
  },
  //Es soll der Name des aktuell eingeloggten Nutzers dargestellt werden
  //Diese Abfrage wird nur gestartet wenn ein Nutzer eingelogt ist, d.h. ein token gespeichert ist
  //Checken ob ein Mitarbeiter eingeloggt ist. Info wird mittels property an  navBar bzw. alle child components übergeben
  //Info wird über $store geteilt
  async created() {
    if (localStorage.getItem("token") != undefined) {
      try {
        const response = await api.employee.refreshToken();
        localStorage.setItem("token", response.token);
        api.fetcher.defaultHeaders.common["Authorization"] =
          "Bearer" + " " + localStorage.getItem("token");
        this.$store.dispatch("user", response.user);
      } catch {
        localStorage.removeItem("token");
      }
    }
  },
  mounted() {
    setMessageBoxInstance(this.$refs.messageBox);
  },
};
</script>

<style src="@ma/basic-styles/dist/basic-styles.css" />

<style scoped>
.app {
  display: grid;
  grid-template-columns: 12rem auto;
  grid-template-areas: "navigation content";
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.navigation {
  grid-area: navigation;
  padding: 1em;
  border-right: var(--form-element-border-color) solid thin;
  overflow: auto;
}

.hamburger {
  display: none;
  height: 2em;
  width: 2em;
  background: linear-gradient(
    to bottom,
    var(--muted-color),
    var(--muted-color) 20%,
    transparent 20%,
    transparent 40%,
    var(--muted-color) 40%,
    var(--muted-color) 60%,
    transparent 60%,
    transparent 80%,
    var(--muted-color) 80%,
    var(--muted-color) 100%
  );
}

#hamburger-input {
  display: none;
}

.overlay {
  position: fixed;
  background: var(--modal-overlay-background-color);
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  z-index: 10;
  display: none;
}

.content {
  grid-area: content;
  overflow: auto;
  padding: 1em;
}

@media screen and (max-width: 1000px) {
  .app {
    display: block;
  }

  .content {
    width: 100%;
    height: 100%;
  }

  .back {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .navigation {
    border: none;
  }

  .navigation-bar {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    background: var(--background-color);
    z-index: 20;
    padding: 1em;
    transition: 0.5s;
    overflow: auto;
  }

  #hamburger-input:checked + .hamburger-label .navigation-bar {
    left: 0;
  }

  .overlay {
    display: block;
    transition: 0.5s;
  }

  #hamburger-input:checked + .hamburger-label .overlay {
    width: 100%;
  }
}
</style>
