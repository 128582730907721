import fetcher from "./fetcher";

import customer from "./customer";
import employee from "./employee";
import licencePlate from "./licence-plate";
import log from "./log";
import login from "./login";
import order from "./order";
import position from "./position";

export {
  fetcher,
  customer,
  employee,
  licencePlate,
  log,
  login,
  order,
  position,
};

export default {
  fetcher,
  customer,
  employee,
  licencePlate,
  log,
  login,
  order,
  position,
};
