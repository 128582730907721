import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store.js";
import api from "./api";
import MessageBox from "./mixins/message-box";

api.fetcher.defaultHeaders.common["Authorization"] =
  "Bearer" + " " + localStorage.getItem("token");

const app = createApp(App);
app.mixin(MessageBox);
app.use(store);
app.use(router);

app.mount("#app");
