import fetcher from "./fetcher";

const BASE_URL = "/api/licencePlate";

class LicencePlate {
  async list(queryParams = {}) {
    const queryString = new URLSearchParams(queryParams);

    const response = await fetcher.get(`${BASE_URL}?${queryString}`);

    return response.data;
  }

  async get(id) {
    const response = await fetcher.get(`${BASE_URL}/${id}`);

    return response.data;
  }

  async update(id, licencePlate) {
    const response = await fetcher.put(`${BASE_URL}/${id}`, licencePlate);

    return response.data;
  }
}

export default new LicencePlate();
