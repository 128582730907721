import fetcher from "./fetcher";

const BASE_URL = "/api/login";

export default async function login(username, password) {
  const response = await fetcher.post(`${BASE_URL}`, {
    username,
    password,
  });

  return response.data;
}
