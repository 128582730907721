import fetcher from "./fetcher";

const BASE_URL = "/api/order";

function dto2Order(dto) {
  const result = { ...dto };

  if (result.storeDate) {
    result.storeDate = new Date(result.storeDate);
  }

  if (result.outsourceDate) {
    result.outsourceDate = new Date(result.outsourceDate);
  }

  if (result.plannedEndOfStore) {
    result.plannedEndOfStore = new Date(result.plannedEndOfStore);
  }
  return result;
}

class Order {
  async list(queryParams = {}) {
    const queryString = new URLSearchParams(queryParams);

    const response = await fetcher.get(`${BASE_URL}?${queryString}`);

    return response.data.map(dto2Order);
  }

  async listPending(queryParams = {}) {
    const queryString = new URLSearchParams(queryParams);

    const response = await fetcher.get(`${BASE_URL}/pending?${queryString}`);

    let result = response.data;

    if (Array.isArray(result)) {
      result = result.map(dto2Order);
    }

    return result;
  }
  //Orders within a certain timespan
  async inTimespan(queryParams = {}) {
    const queryString = new URLSearchParams(queryParams);

    const response = await fetcher.get(`${BASE_URL}/inTimespan?${queryString}`);

    let result = response.data;
    //Map funktioniert nur mit Array, deshalb Array-Check
    if (Array.isArray(result)) {
      result = result.map(dto2Order);
    }

    return result;
  }
  //
  async pendingTirecheck(queryParams = {}) {
    const queryString = new URLSearchParams(queryParams);

    const response = await fetcher.get(`
      ${BASE_URL}/pendingTirecheck?${queryString}`);

    let result = response.data;

    //Map funktioniert nur mit Array, deshalb Array-Check
    if (Array.isArray(result)) {
      result = result.map(dto2Order);
    }

    return result;
  }

  async get(id) {
    const response = await fetcher.get(`${BASE_URL}/${id}`);

    return dto2Order(response.data);
  }

  async update(id, order) {
    const response = await fetcher.put(`${BASE_URL}/${id}`, order);

    return dto2Order(response.data);
  }

  async outsource(id) {
    const response = await fetcher.put(`${BASE_URL}/${id}/outsource`);

    return {
      order: dto2Order(response.data.order),
      repositioning: response.data.repositioning,
    };
  }
}

export { dto2Order };
export default new Order();
