import axios from "axios";
import ApiError from "./ApiError";

class Fetcher {
  get defaultHeaders() {
    return axios.defaults.headers;
  }

  set defaultHeaders(headers) {
    axios.defaults.headers = headers;
  }

  async request(url, config = {}) {
    try {
      return await axios.request({ ...config, url });
    } catch (error) {
      throw new ApiError(error.response, error.request, error.message);
    }
  }

  get(url, config = {}) {
    return this.request(url, { method: "get", ...config });
  }

  delete(url, config = {}) {
    return this.request(url, { method: "delete", ...config });
  }

  head(url, config = {}) {
    return this.request(url, { method: "head", ...config });
  }

  options(url, config = {}) {
    return this.request(url, { method: "options", ...config });
  }

  post(url, data = null, config = {}) {
    return this.request(url, { method: "post", ...config, data });
  }

  put(url, data = null, config = {}) {
    return this.request(url, { method: "put", ...config, data });
  }

  patch(url, data = null, config = {}) {
    return this.request(url, { method: "patch", ...config, data });
  }
}

export default new Fetcher();
