<template>
  <aside>
    <nav>
      <ul>
        <li>
          <div>Mitarbeiter:</div>
          <div v-if="user">{{ user.firstName }} {{ user.lastName }}</div>
          <div v-if="vuexStoreIsEmpty('user')">Kein Mitarbeiter eingeloggt</div>
        </li>
      </ul>
      <ul>
        <li v-if="vuexStoreIsEmpty('user')">
          <router-link to="/">Login</router-link>
        </li>
        <li v-if="!vuexStoreIsEmpty('user')">
          <a @click="handleClickLogOut" to="/">
            Logout
            <AutoLogout />
          </a>
        </li>
        <li>
          <router-link to="/kundeSuche">Kunde</router-link>
        </li>
        <li>
          <router-link to="/suchen">Suche</router-link>
        </li>
        <li>
          <router-link to="/register">Einstellungen</router-link>
        </li>
      </ul>
      <ul>
        <li>
          <router-link
            v-if="!vuexStoreIsEmpty('user') && user.admin == 1"
            to="/adminsettings"
            >Admineinstellungen</router-link
          >
        </li>
        <li>
          <router-link
            v-if="!vuexStoreIsEmpty('user') && user.admin == 1"
            to="/reports"
            >Berichte</router-link
          >
        </li>
        <li>
          <router-link
            v-if="!vuexStoreIsEmpty('user') && user.admin == 1"
            to="/log"
            >Log-Buch</router-link
          >
        </li>
        <!--Lagerfläche anpassen, kann wieder eingeblendet werden, wenn ausprogrammiert.   <li>
          <router-link
            v-if="!vuexStoreIsEmpty('user') && user.admin == 1"
            to="/editStorage"
            >Lagerfläche anpassen</router-link
          >
        </li>-->
      </ul>
    </nav>
  </aside>
</template>

<script>
import AutoLogout from "../components/AutoLogout.vue";

export default {
  components: {
    AutoLogout,
  },
  mounted() {
    this.$store.dispatch("user", null);
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    customer() {
      return this.$store.state.customer;
    },
  },
  methods: {
    handleClickLogOut() {
      localStorage.removeItem("token");
      this.$store.dispatch("user", null);
      this.$store.dispatch("customer", null);
      this.$router.push("/");
      this.$router.go(0);
    },
    vuexStoreIsEmpty(storeElement) {
      if (
        this[storeElement] != undefined &&
        JSON.stringify(this[storeElement]) != "{}" &&
        this[storeElement] != null
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
