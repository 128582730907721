<template>
  <span>({{ timeToGoMinutes }})</span>
</template>

<script>
import api from "../api";
export default {
  data() {
    return {
      //Liste mit Events welche dazu führen, dass der User als "aktiv" gesehen wird => timer wird zurückgesetzt
      events: ["click", "mousedown", "scoll", "keypress", "load"], //"mousemove", dieses Event wurde aus der Reset-Liste genommen, da sonst permanent die Reset Funktion gestartet wird.
      autoLogoutTime: 300, //Zeit bis zum automatischen Logout in Sekunden => Zeit bis eine Warnung ausgegeben wird sind 10% davon, bei 300s => 30s
      warningTimer: null,
      warningZone: false,
      logoutTimer: null,
      timeToGo: 0,
      timeToGoMinutes: "5:00 s",
      lastTokenRefresh: new Date(),
    };
  },
  mounted() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimer);
    });
    this.setTimers();

    setInterval(() => {
      if (this.timeToGo > 0) --this.timeToGo;
      //Überprüfen ob die Zeit über einer Minute liegt, dann wird die Minutenstelle auch angezeigt
      if (Math.floor(this.timeToGo / 60)) {
        this.timeToGoMinutes = `${Math.floor(this.timeToGo / 60)}:${(
          this.timeToGo % 60
        )
          .toString()
          .padStart(2, "0")} s`;
      } else {
        this.timeToGoMinutes = `${(this.timeToGo % 60)
          .toString()
          .padStart(2, "0")} s`;
      }
    }, 1000);
  },
  unmounted() {
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimer);
    });
    this.resetTimer();
  },
  methods: {
    setTimers() {
      this.warningTimer = setTimeout(
        this.warningMessage,
        (this.autoLogoutTime - Math.floor(this.autoLogoutTime / 10)) * 1000
      );
      this.logoutTimer = setTimeout(
        this.logoutUser,
        this.autoLogoutTime * 1000
      );
      this.timeToGo = this.autoLogoutTime; //Zeit bis zum Logout für Countdown
      if (this.warningZone) {
        this.$closeMessageBox();
      }
      this.warningZone = false;
    },
    warningMessage() {
      this.warningZone = true;
      this.$messageBox(
        "Sie werden in Kürze automatisch ausgeloggt, wenn kein Zeichen von Aktivität erfolgt!",
        "Achtung automatischer Logout!",
        "ok"
      );
    },
    logoutUser() {
      localStorage.removeItem("token");
      this.$store.dispatch("user", null);
      this.$store.dispatch("customer", null);
      this.$router.push("/");
      this.$router.go(0);
    },
    async resetTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();

      if (
        new Date().getTime() - this.lastTokenRefresh.getTime() >
        (this.autoLogoutTime * 1000) / 2
      ) {
        this.lastTokenRefresh = new Date();
        const response = await api.employee.refreshToken();
        localStorage.setItem("token", response.token);
        api.fetcher.defaultHeaders.common["Authorization"] =
          "Bearer" + " " + localStorage.getItem("token");
      }
    },
  },
};
</script>
