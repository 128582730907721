import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/LoginView.vue";
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },

  {
    path: "/register",
    name: "mitarbeitersignup",
    component: () =>
      import(
        /* webpackChunkName: "MitarbeiterSignup" */
        "../components/SettingsComponent.vue"
      ),
    meta: {
      needsAuth: true,
    },
  },

  {
    path: "/kundeSuche",
    name: "kundeSuche",
    component: () =>
      import(
        /* webpackChunkName: "kundeSuchen" */
        "../views/KundeSucheView.vue"
      ),
    meta: {
      needsAuth: true,
    },
  },

  {
    path: "/kunde/:id",
    name: "kunde",
    props: true,
    component: () =>
      import(/* webpackChunkName: "kunde" */ "../views/KundeView.vue"),
    meta: {
      needsAuth: true,
    },
  },

  {
    path: "/Kundeneu",
    name: "Kundeneu",
    component: () =>
      import(/* webpackChunkName: "neuerKunde" */ "../views/KundeNeuView.vue"),
    meta: {
      needsAuth: true,
    },
  },

  {
    path: "/einlagern/:customerId",
    name: "einlagern",
    props: true,
    component: () =>
      import(/* webpackChunkName: "einlagern" */ "../views/EinlagernView.vue"),
    meta: {
      needsAuth: true,
    },
  },

  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },

  {
    path: "/ordersHistory/:customerId",
    name: "ordersHistory",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "ordersHistory" */
        "../views/AuftragsHistorie.vue"
      ),
    meta: {
      needsAuth: true,
    },
  },

  {
    path: "/suchen",
    name: "suchen",
    component: () =>
      import(/* webpackChunkName: "SearchView" */ "../views/SuchenView.vue"),
    meta: {
      needsAuth: true,
    },
  },

  {
    path: "/log",
    name: "logbuch",
    component: () =>
      import(/* webpackChunkName: "LogBuch" */ "../views/LogBuch.vue"),
    meta: {
      needsAuth: true,
    },
    beforeEnter(to, from, next) {
      if (store.getters.user.admin) {
        next();
      } else {
        next("/");
      }
    },
  },

  {
    path: "/order/:id",
    name: "order",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Lagerdetails" */
        "../views/LagerungsDetails.vue"
      ),
    meta: {
      needsAuth: true,
    },
  },

  {
    // external entry-point for qr
    path: "/extern/v1/order/:id",
    name: "extern-order",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Lagerdetails" */
        "../views/LagerungsDetails.vue"
      ),
    meta: {
      needsAuth: true,
    },
  },

  {
    path: "/adminsettings",
    name: "adminpage",
    component: () =>
      import(/* webpackChunkName: "adminsettings" */ "../views/AdminPage.vue"),
    meta: {
      needsAuth: true,
    },
    beforeEnter(to, from, next) {
      if (store.getters.user.admin) {
        next();
      } else {
        next("/");
      }
    },
  },

  {
    path: "/employeeNew",
    name: "employeeNew",
    component: () =>
      import(/* webpackChunkName: "neuerKunde" */ "../views/EmployeeNew.vue"),
    meta: {
      needsAuth: true,
    },
    beforeEnter(to, from, next) {
      if (store.getters.user.admin) {
        next();
      } else {
        next("/");
      }
    },
  },

  {
    path: "/EmployeeAdminPage/:employeeId",
    name: "EmployeeAdminpage",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "adminsettings" */
        "../views/EmployeeAdminView.vue"
      ),
    meta: {
      needsAuth: true,
    },
    beforeEnter(to, from, next) {
      if (store.getters.user.admin) {
        next();
      } else {
        next("/");
      }
    },
  },

  {
    path: "/reports",
    name: "reports",
    component: () =>
      import(/* webpackChunkName: "reports" */ "../views/BerichteView.vue"),
    meta: {
      needsAuth: true,
    },
    beforeEnter(to, from, next) {
      if (store.getters.user.admin) {
        next();
      } else {
        next("/");
      }
    },
  },

  {
    path: "/editStorage",
    name: "editStorage",
    component: () =>
      import(
        /* webpackChunkName: "editStorage" */
        "../views/EditStorageArea.vue"
      ),
    meta: {
      needsAuth: true,
    },
    beforeEnter(to, from, next) {
      if (store.getters.user.admin) {
        next();
      } else {
        next("/");
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.needsAuth) {
    if (localStorage.getItem("token")) {
      next();
    } else {
      //Navigiert man von außen zu einer Route, welche erfordert, dass man eingeloggt ist und man ist nicht eingeloggt, so soll man nach dem Login zu dieser Seite weitergeleitet werden
      //Der vom Nutzer gewünschte Pfad wird in Form eines Parameters mitgeschickt und so der Nutzer nach erfolgreichem Login, auf diesen Pfad geschickt
      next({ path: "/", query: { nextUrl: to.fullPath, targetName: to.name } });
    }
  } else {
    next();
  }
});

export default router;
