import fetcher from "./fetcher";
import { dto2Order } from "./order";

const BASE_URL = "/api/log";

function dto2Log(dto) {
  const result = { ...dto };

  if (result.createdAt) {
    result.createdAt = new Date(result.createdAt);
  }

  if (result.order) {
    result.order = dto2Order(result.order);
  }

  return result;
}

class Log {
  async list(queryParams = {}) {
    queryParams._orderBy ??= "createdAt";
    queryParams._orderDirection ??= "DESC";

    const queryString = new URLSearchParams(queryParams);

    const response = await fetcher.get(`${BASE_URL}?${queryString}`);

    if (queryParams._count) {
      return response.data;
    }

    return response.data.map(dto2Log);
  }

  async get(id) {
    const response = await fetcher.get(`${BASE_URL}/${id}`);

    return dto2Log(response.data);
  }
}

export { dto2Log };
export default new Log();
