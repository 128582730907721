export default class ApiError extends Error {
  response = null;
  request = null;
  message = null;

  constructor(response, request, message) {
    super(message);

    this.response = response;
    this.request = request;
    this.message = message;
  }
}
