<template>
  <dialog ref="popup">
    <slot v-if="isOpen" />
  </dialog>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      resolve: null,
      reject: null,
    };
  },
  methods: {
    open() {
      this.$refs.popup.showModal();
      this.isOpen = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close(returnValue = undefined) {
      this.resolve?.(returnValue);
      this.resolve = null;
      this.reject = null;
      this.$refs.popup.close();
      this.isOpen = false;
    },
    abort(reason = undefined) {
      this.reject?.(reason);
      this.resolve = null;
      this.reject = null;
      this.$refs.popup.close();
      this.isOpen = false;
    },
  },
};
</script>
