<template>
  <pop-up ref="popup">
    <article>
      <h3 v-if="title">
        {{ title }}
      </h3>
      <p class="message">
        {{ message }}
      </p>
      <footer>
        <a v-if="showOk" href="#" role="button" @click.prevent="close()">
          OK
        </a>
        <a v-if="showYes" href="#" role="button" @click.prevent="close()">
          Ja
        </a>
        <a v-if="showNo" href="#" role="button" @click.prevent="abort()">
          Nein
        </a>
      </footer>
    </article>
  </pop-up>
</template>

<script>
import PopUp from "./PopUp.vue";

const buttonDefintions = {
  ok: {
    showOk: true,
  },
  info: {
    showOk: true,
  },
  yesno: {
    showYes: true,
    showNo: true,
  },
  question: {
    showYes: true,
    showNo: true,
  },
};

export default {
  components: { PopUp },
  data() {
    return {
      message: "",
      title: "",
      showYes: false,
      showNo: false,
      showOk: false,
    };
  },
  methods: {
    show(message, title = "", type = "ok") {
      this.message = message;
      this.title = title;

      this.showYes = false;
      this.showNo = false;
      this.showOk = false;

      const typeButtons = buttonDefintions[type] ?? buttonDefintions.ok;
      Object.entries(typeButtons).forEach(([key, val]) => {
        this[key] = val;
      });

      return this.$refs.popup.open();
    },
    close() {
      this.$refs.popup.close();
    },
    abort() {
      this.$refs.popup.abort();
    },
  },
};
</script>

<style scoped>
.message {
  white-space: pre-line;
}
</style>
