<template>
  <form @submit.prevent="handleLogin">
    <h3>Login</h3>
    <label>
      Benutzername:
      <input
        type="text"
        v-model="username"
        placeholder="Benutzername"
        autocomplete="off"
      />
    </label>
    <label>
      Passwort:
      <input
        type="password"
        v-model="password"
        placeholder="Passwort"
        autocomplete="off"
      />
    </label>
    <button>Login</button>
  </form>
</template>

<script>
import api from "../api";

export default {
  name: "LoginComponent",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    async handleLogin() {
      try {
        const response = await api.login(this.username, this.password);
        localStorage.setItem("token", response.token); //Token lokal speichern
        this.$store.dispatch("user", response.user); //user im Store ablegen
        //Der Token muss nochmal manuell in den Auth Header gehauen werden, sollte eigentlich axios.js machen, das funktioniert allerdings nur nach Page Refresh
        //Deshalb wurde das so gelöst
        api.fetcher.defaultHeaders.common["Authorization"] =
          "Bearer" + " " + localStorage.getItem("token");
        //Wurde für den Login eine Wunschadress mitgeschickt, wo der Nutzer gerne hin will, wird diese geöffnet, ansonsten eine Defaultadresse, wo man nach erfolgreichem Login hingeleitet wird
        if (
          this.$route.query.targetName != undefined &&
          this.$route.query.nextUrl != undefined &&
          this.$route.query.targetName != "login"
        ) {
          this.$router.push(this.$route.query.nextUrl);
        } else {
          this.$router.push("/kundeSuche");
        }
      } catch (e) {
        this.$messageBox(
          "Nutzername oder Passwort falsch",
          "Login fehlgeschlagen",
          "ok"
        );
      }
    },
  },
};
</script>
