let messageBoxInstance = null;

export function setMessageBoxInstance(instance) {
  messageBoxInstance = instance;
}

export default {
  methods: {
    $messageBox(message, title = "", type = "ok") {
      return messageBoxInstance?.show(message, title, type);
    },
    $closeMessageBox() {
      messageBoxInstance?.close();
    },
    $abortMessageBox() {
      messageBoxInstance?.abort();
    },
  },
};
