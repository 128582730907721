import fetcher from "./fetcher";
import { dto2Order } from "./order";

const BASE_URL = "/api/customer";

class Customer {
  async list(queryParams = {}) {
    const queryString = new URLSearchParams(queryParams);

    const response = await fetcher.get(`${BASE_URL}?${queryString}`);

    return response.data;
  }

  async get(id) {
    const response = await fetcher.get(`${BASE_URL}/${id}`);

    return response.data;
  }

  async new(customer) {
    const response = await fetcher.post(`${BASE_URL}`, customer);

    return response.data;
  }

  async update(id, customer) {
    const response = await fetcher.put(`${BASE_URL}/${id}`, customer);

    return response.data;
  }

  async listLicencePlates(id, queryParams) {
    const queryString = new URLSearchParams(queryParams);

    const response = await fetcher.get(
      `${BASE_URL}/${id}/licencePlate?${queryString}`
    );

    return response.data;
  }

  async addLicencePlate(id, licencePlate) {
    const response = await fetcher.post(
      `${BASE_URL}/${id}/licencePlate`,
      licencePlate
    );

    return response.data;
  }

  async listOrders(id, queryParams) {
    const queryString = new URLSearchParams(queryParams);
    const response = await fetcher.get(
      `${BASE_URL}/${id}/order?${queryString}`
    );

    let result = response.data;

    if (Array.isArray(result)) {
      result = result.map(dto2Order);
    }

    return result;
  }

  async listPendingOrders(id, queryParams) {
    const queryString = new URLSearchParams(queryParams);

    const response = await fetcher.get(
      `${BASE_URL}/${id}/order/pending?${queryString}`
    );

    let result = response.data;

    if (Array.isArray(result)) {
      result = result.map(dto2Order);
    }

    return result;
  }

  async addOrder(id, order) {
    const response = await fetcher.post(`${BASE_URL}/${id}/order`, order);

    return response.data;
  }
}

export default new Customer();
