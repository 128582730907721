import fetcher from "./fetcher";

const BASE_URL = "/api/employee";

class Employee {
  async list(queryParams = {}) {
    const queryString = new URLSearchParams(queryParams);

    const response = await fetcher.get(`${BASE_URL}?${queryString}`);

    return response.data;
  }

  async get(id) {
    const response = await fetcher.get(`${BASE_URL}/${id}`);

    return response.data;
  }

  async new(employee) {
    const response = await fetcher.post(`${BASE_URL}`, employee);

    return response.data;
  }

  async update(id, employee) {
    const response = await fetcher.put(`${BASE_URL}/${id}`, employee);

    return response.data;
  }

  async setPassword(id, password) {
    await fetcher.put(`${BASE_URL}/${id}/password`, { password });
  }

  async refreshToken() {
    const response = await fetcher.get(`${BASE_URL}/refreshToken`);

    return response.data;
  }
}

export default new Employee();
