//import Vue from "vue";
import Vuex from "vuex";

//Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: {},
    customer: {},
  },
  //get current states
  getters: {
    user: (state) => {
      return state.user;
    },
    customer: (state) => {
      return state.customer;
    },
  },
  //change value of states => but not changed on the action itself
  actions: {
    user(context, user) {
      context.commit("user", user);
    },
    customer(context, customer) {
      context.commit("customer", customer);
    },
  },
  //state values are changed on the mutations
  mutations: {
    user(state, user) {
      state.user = user;
    },
    customer(state, customer) {
      state.customer = customer;
    },
  },
});
export default store;
